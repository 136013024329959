<template>
  <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.08187 2.42604C7.21632 2.15365 7.28355 2.01746 7.37481 1.97395C7.45421 1.93609 7.54646 1.93609 7.62586 1.97395C7.71712 2.01746 7.78435 2.15365 7.9188 2.42604L9.19436 5.01018C9.23405 5.0906 9.2539 5.1308 9.2829 5.16202C9.30858 5.18966 9.33938 5.21205 9.37359 5.22796C9.41223 5.24593 9.4566 5.25242 9.54533 5.26539L12.3986 5.68243C12.699 5.72635 12.8493 5.7483 12.9188 5.82169C12.9793 5.88554 13.0077 5.97327 12.9962 6.06047C12.983 6.16069 12.8742 6.26662 12.6567 6.47849L10.5928 8.48868C10.5285 8.55134 10.4963 8.58267 10.4756 8.61995C10.4572 8.65296 10.4454 8.68923 10.4409 8.72673C10.4357 8.76909 10.4433 8.81335 10.4585 8.90187L10.9455 11.7412C10.9968 12.0406 11.0225 12.1904 10.9742 12.2792C10.9323 12.3565 10.8576 12.4108 10.7711 12.4268C10.6717 12.4452 10.5372 12.3745 10.2683 12.2331L7.71755 10.8917C7.63807 10.8499 7.59833 10.829 7.55647 10.8208C7.5194 10.8135 7.48127 10.8135 7.4442 10.8208C7.40234 10.829 7.3626 10.8499 7.28312 10.8917L4.73236 12.2331C4.46344 12.3745 4.32898 12.4452 4.22956 12.4268C4.14306 12.4108 4.06841 12.3565 4.02642 12.2792C3.97816 12.1904 4.00384 12.0406 4.0552 11.7412L4.54218 8.90187C4.55736 8.81335 4.56495 8.76909 4.55981 8.72673C4.55526 8.68923 4.54347 8.65296 4.5251 8.61995C4.50434 8.58267 4.47217 8.55134 4.40783 8.48868L2.34399 6.47849C2.12646 6.26662 2.0177 6.16069 2.00447 6.06047C1.99295 5.97327 2.0214 5.88554 2.08189 5.82169C2.15141 5.7483 2.30164 5.72635 2.6021 5.68243L5.45534 5.26539C5.54407 5.25242 5.58844 5.24593 5.62708 5.22796C5.66129 5.21205 5.69209 5.18966 5.71777 5.16202C5.74677 5.1308 5.76662 5.0906 5.80631 5.01018L7.08187 2.42604Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconStarOutlined'
}
</script>
